import { countBy, forEach, groupBy } from "lodash";
import { HistoryDataFilter, checkUndefinedAndNull, checkUndefinedAndNullEmpty,checkUndefinedAndNullLength,filterByMarket } from "./helper"
import { WidgetViewItemConstants, timeFormat } from "../constants/RTDConstants";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const emitChatStatusData = (socket, filterInfo): any => {
    socket.emit("getChatAnalysisData", filterInfo)
}

export const unsubscribeChatStatusEvent = (socket) => {
    if (socket) {
        socket.off("chatAnalysisData")
    }
}

const ChatAnalysisHelpersTrieData = (response: any, groupField, filterValues: any = []) => {
    var HashMap = require('hashmap');
    let customTable = new HashMap();
    let customData: any = [];
    let groupKey = checkUndefinedAndNullEmpty(groupField) ? groupField : 'Domain'
    if (response && response.length) {
        let responseData = response;
        if (filterValues.length > 0) {
            let filterObj = filterValues[0];
            if (checkUndefinedAndNull(filterObj)) {
                responseData = filterByMarket(responseData, filterObj);
            }
        }
                
        responseData.map((item: any) => {
            if (customTable.get(item[groupKey]) === undefined) {
                customTable.set(item[groupKey], {
                    id: checkUndefinedAndNull(item[groupKey]) ? `Marquet : ${item[groupKey]}` : `Default`,
                    name: checkUndefinedAndNull(item[groupKey]) ? `Marquet : ${item[groupKey]}` : `Default`,
                    'Missed': 0,
                    'Completed': 0,
                    'Offered': 0,
                    'Waiting': 0,
                    'In_Progress': 0,
                    'AWT': 0,
                    'ACD': 0,
                    // 'ART':0,
                    // 'AMRT':0,
                    'WASA': 0,
                    'WAHT': 0,
                    'AbandonedRate': 0,
                    'HandledInSL': 0,
                    'AbandonedInSL': 0,
                    'QTime': 0,
                    timeZone: '',
                    items: [],
                })
            }
            let chatQueueItem = customTable.get(item[groupKey]);
            chatQueueItem.id = checkUndefinedAndNull(item[groupKey]) ? `${item[groupKey]}` : `Default`
            chatQueueItem.QueueName = checkUndefinedAndNull(item[groupKey]) ? `${item[groupKey]}` : `Default`
            chatQueueItem['Missed'] = chatQueueItem['Missed'] + item['Missed']
            chatQueueItem['Completed'] = chatQueueItem['Completed'] + item['Completed']
            let missed = checkUndefinedAndNull(item['Missed']) ? item['Missed'] : 0
            let Completed = checkUndefinedAndNull(item['Completed']) ? item['Completed'] : 0
            item['Offered'] = (missed + Completed)
            chatQueueItem['Offered'] = chatQueueItem['Offered'] + item['Offered']
            chatQueueItem['Waiting'] = chatQueueItem['Waiting'] + item['Waiting']
            chatQueueItem['In_Progress'] = chatQueueItem['In_Progress'] + item['In_Progress']
            //chatQueueItem['AWT'] = chatQueueItem['AWT'] + item['AWT']
            //chatQueueItem['ACD'] = chatQueueItem['ACD'] + item['ACD']
            //chatQueueItem['ART'] = chatQueueItem['ART'] + item['ART']
            //chatQueueItem['AMRT'] = chatQueueItem['AMRT'] + item['AMRT']
            item['ACD'] = item['Completed'] > 0 ? item['WACD'] / item['Completed'] : 0
            item['AWT'] = item['Completed'] > 0 ? item['WAWT'] / item['Completed'] : 0
            item['WAHT'] = item['Completed'] * item['ACD']
            chatQueueItem['WAHT'] = chatQueueItem['WAHT'] + item['WAHT']
            item['WASA'] = item['Completed'] * item['AWT']
            chatQueueItem['WASA'] = chatQueueItem['WASA'] + item['WASA']
            chatQueueItem['HandledInSL'] = chatQueueItem['HandledInSL'] + item['HandledInSL']
            chatQueueItem['AbandonedInSL'] = chatQueueItem['AbandonedInSL'] + item['AbandonedInSL']
            item.AbandonedRate = item['Offered'] > 0 ? (item['Missed'] / item['Offered']) * 100 : 0
            item.ServiceLevel = item['Offered'] > 0 ? ((item['HandledInSL'] + item['AbandonedInSL']) / item['Offered']) * 100 : 0
            //chatQueueItem['QTime'] = chatQueueItem['REEL_QTime']
            chatQueueItem['timeZone'] = item.timeZone
            chatQueueItem.items.push(item);
            return customTable.set(item[groupKey], chatQueueItem);
        })
    }

    customTable.forEach((citem, i) => {
        let childItems = citem.items.map((item, idx) => {
            return {
                key: i + "-" + idx.toString(),
                data: item
            }
        })

        let filterItems = citem.items.filter(a => a.QTime > 0)

        let nodeItem = {
            key: i,
            data: {
                ...citem,
                'ServiceLevel': citem.Offered > 0 ? ((citem.HandledInSL + citem.AbandonedInSL) / citem.Offered) * 100 : 0,
                'AbandonedRate': citem.Offered > 0 ? (citem.Missed / citem.Offered) * 100 : 0,
                'ACD': citem.Completed > 0 ? (citem.WAHT / citem.Completed) : 0,
                'AWT': citem.Completed > 0 ? (citem.WASA / citem.Completed) : 0,
                'QTime': filterItems.length > 0 ? Math.min(...filterItems.map(a => a.QTime)) : 0
            },
            children: childItems
        }

        customData.push(nodeItem)
    })
    return customData;
}

export const ChatQTimeCalc = (Qtime: any): string => {
    let value = Qtime ? Qtime : 0
    const durationObj = dayjs.duration(value * 1000)
    let time = durationObj.format(timeFormat)
    return time;
}

export const emitEmailAnalysisData =(socket, filterInfo): any => {
    socket.emit("getEmailAnalysisData")
}

export const updateEmailAnalysisData = (socket, filterObj, widgetInfo, cb, queryClient) => {
    if (!socket) return true;
    socket.on("emailAnalyticData", (currentData: any) => {
        if(checkUndefinedAndNullLength(currentData)){
            queryClient.setQueryData({ queryKey: ["emailAnalysis"] },currentData)
            let combinedData = processEmailAnalysisData(currentData,filterObj,widgetInfo)
            cb(null, combinedData)
        } else{
            cb(null,currentData)
        }
    })
}

export const unsubscribeEmailAnalysis = (socket) => {
    if (socket) {
        socket.off("emailAnalyticData")
    }
}

export const processEmailAnalysisData = (currentData, filterObj, widgetInfo ) => {
    // const jsonString = JSON.stringify(currentData);
    // const sizeInBytes = new TextEncoder().encode(jsonString).length
    // console.log(`Size of array in bytes: ${sizeInBytes}`)

    let filterValues = checkUndefinedAndNull(filterObj) ? filterObj.filterValues : []
    let responseData = currentData//.filter(a=>a.Domain === 'BER_Email_DE');
    if (filterValues.length > 0) {
        let filterObj = filterValues[0];
        if (checkUndefinedAndNull(filterObj)) {
            responseData = filterByMarket(responseData, filterObj);
        }
    }
   
    let groupCallback: (records:any, keys: any) => any = groupEmailAnalysisData
    let groupField = widgetInfo.groupField
    let subLevelGroupFields = widgetInfo.subLevelGroupFields
    let subLevelGroupItems = typeof(subLevelGroupFields) === 'string' ? JSON.parse(subLevelGroupFields) : subLevelGroupFields
    const updatedData = getEmailAnalysisData(responseData,groupCallback,groupField,subLevelGroupItems[0],subLevelGroupItems[1])
    let combinedData = { 'processData': updatedData, 'rowData': responseData.filter(a=>checkUndefinedAndNull(a.CaseNumber))}
    return combinedData
}

export const getEmailAnalysisData = (response,groupCallback: (records:any, keys: any) => any,groupField,secondLevelGroup,thirdLevelGroupField:any=undefined) => {
    if (response && response.length) {
        let recordsByType = groupCallback(response,[groupField])
        let highLevelGroupedData = recordsByType.map((item, key) =>{
            let groupFieldValue = item.Name
            let Level1FilteredData = response.filter(a=>a[groupField] === groupFieldValue)
            let localeRecords = groupCallback(Level1FilteredData,[secondLevelGroup])
            let level2GroupedData = localeRecords.map((item, key) =>{
                let Level2FilteredData = Level1FilteredData.filter(a => a[secondLevelGroup] === item.Name)
                if(checkUndefinedAndNull(thirdLevelGroupField)){
                    let statusGroupedData = groupCallback(Level2FilteredData,[thirdLevelGroupField])
                    statusGroupedData = statusGroupedData.map((a) =>{ 
                        return {
                            key: `${groupFieldValue}:${item.Name}:${a.id}:${key}`, 
                            data:{...a,'id': `${groupFieldValue}:${item.Name}:${a.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}:${thirdLevelGroupField}`}
                        } 
                    })
            
                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}, 
                        children: statusGroupedData
                    }
                } else {
                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}
                    }
                }
            })

            return {
                key: `${item.id}:${key}`, 
                data : {...item, 'idFields': `${groupField}`},
                children: level2GroupedData
            }
        })

        return highLevelGroupedData
    }
}

const groupEmailAnalysisData = (records,keys) => {
    let groupData:any = []
    let outputData = groupBy(records, item => {
        return keys.map(key => item[key]).join(':');
    })
    
    forEach(outputData, (value, key) => {
        let splitKey = key.split(':')
        let sumRequestCount = countBy(value.filter(a=> checkUndefinedAndNull(a.AgeLabel)), 'AgeLabel')
        let ageCriteriaCount = { '<8': 0, '8-16': 0,'16-22': 0,'22-24': 0,'24-48': 0,'48-72': 0,'72-120': 0,'120-240': 0,'>240': 0 }
        let updatedAgeCriteriaCount : any = {...ageCriteriaCount, ...sumRequestCount }

        const total = Object.values(updatedAgeCriteriaCount)
            .reduce((acc: any, value) => acc + (typeof value === 'number' ? value : 0), 0)
    
        if(checkUndefinedAndNullEmpty(key)){
            groupData.push({
                'id': key,
                'Name':splitKey[keys.length - 1],
                ...updatedAgeCriteriaCount,
                'Total': total
            })
        }
    })

    return groupData
}

export const groupChatAgeData = (records,keys) => {
    let groupData:any = []
    
    let outputData = groupBy(records, item => {
        return keys.map(key => item[key]).join(':');
    })
    
    forEach(outputData, (value, key) => {
        let splitKey = key.split(':')
        let sumRequestCount = countBy(value.filter(a => a.Status === 'Completed' && checkUndefinedAndNull(a.AgeLabel)), 'AgeLabel')
        let ageCriteriaCount = { '0-30':0, '30-60': 0,'60-90': 0,'90-120': 0,'>120': 0 }
        let updatedAgeCriteriaCount : any = {...ageCriteriaCount, ...sumRequestCount }

        const total = Object.values(updatedAgeCriteriaCount)
            .reduce((acc: any, value) => acc + (typeof value === 'number' ? value : 0), 0)
    
        if(checkUndefinedAndNullEmpty(key)){
            groupData.push({
                'id': key,
                'QueueName':splitKey[keys.length - 1],
                ...updatedAgeCriteriaCount,
                'Total': total
            })
        }
    })

    return groupData
}

export const groupVisitorChatStatsData = (records, keys) => {
    let groupData:any = []
    let outputData = groupBy(records, item => {
        return keys.map(key => item[key]).join(':');
    })

    forEach(outputData, (value, key) => {
        let splitKey = key.split(':')
        let sumOMC = 0, sumVMC = 0, sumWARTO = 0, sumWARTV = 0, maxMRTO = 0, maxMRTV = 0

        value.forEach(item => {
            sumOMC += item.OMC;
            sumVMC += item.VMC;
            sumWARTO += item.WARTO;
            sumWARTV += item.WARTV;
            maxMRTO = Math.max(maxMRTO, item.MRTO);
            maxMRTV = Math.max(maxMRTV, item.MRTV);
        });

        if(checkUndefinedAndNullEmpty(key)){
            groupData.push({
                'id': key,
                'QueueName': splitKey[keys.length - 1],
                'timeZone': value[0].timeZone ?? '', 
                'OMC': sumOMC,
                'VMC': sumVMC,
                'ARTO': sumOMC > 0 ? Math.round(sumWARTO / sumOMC) : 0,
                'ARTV': sumVMC > 0 ? Math.round(sumWARTV / sumVMC) : 0,
                'WARTO': sumWARTO,
                'WARTV': sumWARTV,
                'MRTO': maxMRTO,
                'MRTV': maxMRTV
            })
        }
    })

    return groupData
}

/// Gettiing Chat Analysis Data Functions
export const processChatAnalysisData = (currentData, filterObj, widgetInfo) => {
    let filterValues = checkUndefinedAndNull(filterObj) ? filterObj.filterValues : []
    let responseData = currentData
    if (filterValues.length > 0) {
        let filterObj = filterValues[0];
        if (checkUndefinedAndNull(filterObj)) {
            responseData = filterByMarket(responseData, filterObj);
        }
    }
    
    if(checkUndefinedAndNullLength(widgetInfo?.historySettings))
    {        
        let savedHistorySettings = JSON.parse(widgetInfo?.historySettings);
        if(checkUndefinedAndNullEmpty(savedHistorySettings)){
            let previousDateResponse = HistoryDataFilter(responseData, savedHistorySettings?.value, savedHistorySettings?.type);
            if(checkUndefinedAndNullLength(previousDateResponse))
            {
                responseData = previousDateResponse
            }
        }
    }
    let groupCallback: (records:any, keys: any) => any = () => {}
    switch(widgetInfo.viewItemId){
        case WidgetViewItemConstants.ChatAnalysisView :
        groupCallback = groupChatAnalysisData
        break
        case WidgetViewItemConstants.ChatAgeAnalysisView :
        groupCallback = groupChatAgeData
        break
        case WidgetViewItemConstants.VisitorChatStatView :
        groupCallback = groupVisitorChatStatsData
        break
    }
    responseData = responseData.map(a => { 
        return { ...a, 
            'Domain': checkUndefinedAndNull(a.Domain) ? a.Domain : 'Default',
            'BusinessVertical': checkUndefinedAndNull(a.BusinessVertical) ? a.BusinessVertical : 'Default',
            'PlaningEntity': checkUndefinedAndNull(a.PlaningEntity) ? a.PlaningEntity : 'Default',
            'ForecastGroup': checkUndefinedAndNull(a.ForecastGroup) ? a.ForecastGroup : 'Default',
            'WebCountry': checkUndefinedAndNull(a.WebCountry) ? a.WebCountry : 'Default',
            'HierarchyL1': checkUndefinedAndNull(a.HierarchyL1) ? a.HierarchyL1 : 'Default',
            'QTime' : checkUndefinedAndNull(a.Status) && a.Status === 'Waiting' ? new Date(a.CreatedDate).getTime() : 0, 
            'Status': checkUndefinedAndNull(a.Status) && a.Status === 'InProgress' ? 'In_Progress': a?.Status,
            'Category': checkUndefinedAndNullEmpty(a.Case?.Category__c) ? a.Case.Category__c : 'Default',
            'ARTO': checkUndefinedAndNull(a.AverageResponseTimeOperator) ? a.AverageResponseTimeOperator : 0,  
            'ARTV': checkUndefinedAndNull(a.AverageResponseTimeVisitor) ? a.AverageResponseTimeVisitor : 0,
            'OMC': checkUndefinedAndNull(a.OperatorMessageCount) ? a.OperatorMessageCount : 0,
            'VMC': checkUndefinedAndNull(a.VisitorMessageCount) ? a.VisitorMessageCount : 0,   
            'MRTO': checkUndefinedAndNull(a.MaxResponseTimeOperator) ? a.MaxResponseTimeOperator : 0,
            'MRTV': checkUndefinedAndNull(a.MaxResponseTimeVisitor) ? a.MaxResponseTimeVisitor : 0,
            'WARTO' : (checkUndefinedAndNull(a.AverageResponseTimeOperator) ? a.AverageResponseTimeOperator : 0) * (checkUndefinedAndNull(a.OperatorMessageCount) ? a.OperatorMessageCount : 0),
            'WARTV' : (checkUndefinedAndNull(a.AverageResponseTimeVisitor) ? a.AverageResponseTimeVisitor : 0) * (checkUndefinedAndNull(a.VisitorMessageCount) ? a.VisitorMessageCount : 0)
         } 
    })
    
    let groupField = widgetInfo.groupField
    let subLevelGroupFields = widgetInfo.subLevelGroupFields

    let subLevelGroupItems = typeof(subLevelGroupFields) === 'string' ? JSON.parse(subLevelGroupFields) : subLevelGroupFields
    const updatedData = getChatAnalysisData(responseData,groupCallback,groupField,subLevelGroupItems[0],subLevelGroupItems[1])
    return updatedData
}

export const groupChatAnalysisData = (records, keys) => {
    let groupData:any = []
    let outputData = groupBy(records, item => {
        return keys.map(key => item[key]).join(':');
    })

    forEach(outputData, (items, key) => {
        let sumAWT = 0, sumACD = 0, sumART = 0, sumAMRT = 0
        let splitKey = key.split(':')
        let sumRequestCount = countBy(items.filter(a=> checkUndefinedAndNull(a.Status)), 'Status')
        let ageCriteriaCount = { 'Missed': 0, 'Completed': 0,'In_Progress': 0,'Waiting': 0 }
        let statusCount : any = {...ageCriteriaCount, ...sumRequestCount }

        let completedRecords = items.filter(a=>a.Status === 'Completed')
        let queueSLRecords = completedRecords.filter(a=> a.WaitTime <= 30)
        let abandonedRecords = items.filter(a=>a.Status === 'Missed' && a.Abandoned <= 30)
        let HandledInSL =  queueSLRecords.length > 0 ? queueSLRecords.length : 0
        let AbandonedInSL =  abandonedRecords.length > 0 ? abandonedRecords.length : 0 
        let Offered = statusCount.Missed + statusCount.Completed

        
        completedRecords.forEach(item => {
            sumAWT += item.WaitTime;
            sumACD += item.ChatDuration;
            sumART += item.AverageResponseTimeOperator;
            sumAMRT += item.MaxResponseTimeOperator;
        });
    
        if(checkUndefinedAndNullEmpty(key)){
            groupData.push({
                'id': key,
                'QueueName':splitKey[keys.length - 1],
                'timeZone': items[0].timeZone ?? '',
                ...statusCount,
                'AWT' : completedRecords.length > 0 ? Math.round(sumAWT / completedRecords.length) : 0,
                'ACD': completedRecords.length > 0 ? Math.round(sumACD / completedRecords.length) : 0,
                'ART' : completedRecords.length > 0 ? Math.round(sumART / completedRecords.length) : 0,
                'AMRT': completedRecords.length > 0 ? Math.round(sumAMRT / completedRecords.length) : 0,
                'Offered': Offered,
                'HandledInSL': HandledInSL,
                'AbandonedInSL': AbandonedInSL, 
                'AbandonedRate': Offered > 0 ? (statusCount.Missed / Offered) * 100 : 0,
                'ServiceLevel': Offered > 0 ? ((HandledInSL + AbandonedInSL) / Offered) * 100: 0,
                'QTime':Math.max(...items.map(item => item.QTime))
            })
        }
    })

    return groupData
}

const getChatAnalysisData = (response,groupCallback: (records:any, keys: any) => any,groupField,secondLevelGroup,thirdLevelGroupField:any=undefined) => {
    if (response && response.length) {
        let recordsByType = groupCallback(response,[groupField])
        let highLevelGroupedData = recordsByType.map((item, key) =>{
            let groupFieldValue = item.QueueName
            let Level1FilteredData = response.filter(a=>a[groupField] === groupFieldValue)
            let localeRecords = groupCallback(Level1FilteredData,[secondLevelGroup])
            let level2GroupedData = localeRecords.map((item, key) =>{
                let Level2FilteredData = Level1FilteredData.filter(a => a[secondLevelGroup] === item.QueueName)
                if(checkUndefinedAndNull(thirdLevelGroupField)){
                    let statusGroupedData = groupCallback(Level2FilteredData,[thirdLevelGroupField])
                    statusGroupedData = statusGroupedData.map((a) =>{ 
                        return {
                            key: `${groupFieldValue}:${item.QueueName}:${a.id}:${key}`, 
                            data:{...a,'id': `${groupFieldValue}:${item.QueueName}:${a.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}:${thirdLevelGroupField}`}
                        } 
                    })
            
                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}, 
                        children: statusGroupedData
                    }
                } else {
                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}
                    }
                }
            })

            return {
                key: `${item.id}:${key}`, 
                data : {...item, 'idFields': `${groupField}`},
                children: level2GroupedData
            }
        })

        return highLevelGroupedData
    } else {
        return []
    }
}