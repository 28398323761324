import { HCPMasterPagesTabValue,HCPMappingTabValue, HCPAlertTabValue } from "../../../enums/common.enum";

export const apiUrl = "hcp/"
export const getHeadCountDetailUrl = apiUrl + "HeadCount/getheadcountdetails";
export const getHeadCountAlertDetailUrl = apiUrl + "HeadCount/getnotifications";
export const getHCPAlertApplyUrl = apiUrl + "HeadCount/applyhcnotifications";
export const getHCdetailsbyIdUrl = apiUrl + "HeadCount/getHCdetailsbyId";
export const manageHCAttributesUrl = apiUrl + "HeadCount/managehcattributes";
export const getAttributesListUrl = apiUrl + "HeadCount/getattributes";
export const getWorkGroupUrl = apiUrl + "HeadCount/getworkgroups";
export const getMasterPagesListUrl = apiUrl + "MasterPages/getMasterPagesDetails";
export const manageMasterPagesUrl = apiUrl + "MasterPages/manageMasterPages";
export const deleteMasterPagesDetailsUrl = apiUrl + "MasterPages/deleteMasterPageDetails";
export const getMappingDetailsUrl = apiUrl + "MasterPages/getMappingDetails";
export const deleteMappingDetailsUrl = apiUrl + "MasterPages/deleteMappingDetails";
export const getDropdownsUrl = apiUrl + "MasterPages/getDropdowns";
export const manageMappingPagesUrl = apiUrl + "MasterPages/manageMappingPages";
export const getfilterCriteriaDataUrl=apiUrl + "Filter/getfilterCriteriaData";
export const saveFilterSettingUrl = apiUrl + "Filter/saveFilterSettings";
export const getFilterSettingsUrl = apiUrl + "Filter/getFilterSettings";
export const deleteFilterSettingUrl = apiUrl + "Filter/deleteFilterSettings";
export const getCostCenterUrl = apiUrl + "MasterPages/getcostCenters";
export const getAlertDetailsUrl = apiUrl + "HeadCount/getNotificationDetails";
export const applyCustomUpdateAlertUrl = apiUrl + "HeadCount/applycustomupdatenotifications";
export const getWeeksUrl = apiUrl + "HeadCountHistorical/getweeks";
export const getheadcounthistoricaldetailsUrl = apiUrl + "HeadCountHistorical/getheadcounthistoricaldetails";
export const getAlertFiltersUrl = apiUrl + "Filter/getAlertFilters";
export const getalertsfilterCriteriaDataUrl=apiUrl + "Filter/getAlertsFilterCriteriaData";

export const AbsenseTypeOptions = [
  { 'name': 'MAT_PAT', 'value': 'matpat' }, 
  { 'name': 'LOA', 'value': 'loa' },
  { 'name': 'Non_Productive', 'value': 'nonproductive' },
  { 'name': 'Suspension', 'value': 'suspension'},
];

export const AttributeConstants = {
  Absence: "Absence",
  CostCenterID: "CostCenterID",
  WorkGroup: "WorkGroup"  
};

export class GenericConstants {
  static readonly Tabs = [
    {
      headerKey: "WORK GROUP",
      addLabelKey: "Add Work group",
      editLabelKey: "Edit Work group",
      value: HCPMasterPagesTabValue.Workgroup
    },
    {
      headerKey: "COST CENTER",
      addLabelKey: "Add Cost Center",
      editLabelKey: "Edit Cost Center",
      value: HCPMasterPagesTabValue.CostCenter
    },
    {
      headerKey: "FORECAST GROUP",
      addLabelKey: "Add Forecast Group",
      editLabelKey: "Edit ForeCast Group",
      value: HCPMasterPagesTabValue.ForecastGroup
    },
    {
      headerKey: "CONTACT TYPE",
      addLabelKey: "Add Contact Type",
      editLabelKey: "Edit Contact Type",
      value: HCPMasterPagesTabValue.ContactType
    },
    {
      headerKey: "WEB COUNTRY",
      addLabelKey: "Add Web Country",
      editLabelKey: "Edit Web Country",
      value: HCPMasterPagesTabValue.WebCountry
    }
  ]
}

export class MappingGenericConstants {
  static readonly Tabs = [
    {
      headerKey: "COST CENTER",
      addLabelKey: "Add Cost Center Mapping",
      editLabelKey: "Edit Cost Center Mapping",
      value: HCPMappingTabValue.CostCenter
    },    
    {
      headerKey: "WORK GROUP",
      addLabelKey: "Add Work group Mapping",
      editLabelKey: "Edit Work group Mapping",
      value: HCPMappingTabValue.Workgroup
    }    
  ]
}

export const levelOptions = [
  { id: 1, name: "MG1" },
  { id: 2, name: "MG2" },
  { id: 3, name: "PR1" },  
  { id: 4, name: "PR2" },  
  { id: 5, name: "PR3" }, 
  { id: 6, name: "SU1" }, 
  { id: 7, name: "SU2" },
  { id: 8, name: "SU3" },
  { id: 9, name: "SU4" },
];

export const siteOptions = [
  { id: 1, name: "MBY" },
  { id: 2, name: "MNL" },
  { id: 3, name: "TUN" },  
  { id: 4, name: "BER" },  
  { id: 5, name: "ESP" }, 
  { id: 6, name: "Venlo" }, 
  { id: 7, name: "Windsor" },
];
export const orgOptions = [
  { id: 1, name: "CCO" },
  { id: 2, name: "CDO" },
];
export const planningEntityOptions = [
  { id: 1, name: "ANZ" },
  { id: 2, name: "DE" },
  { id: 3, name: "DK" },  
  { id: 4, name: "ES" },  
  { id: 5, name: "NA" }, 
  { id: 6, name: "FI" }, 
  { id: 7, name: "FR" },
  { id: 8, name: "UK" },
  { id: 9, name: "SE" },
];
export const costCenterTypeOptions = [
  { id: 1, name: "CCO Direct"},
  { id: 2, name: "CCO Indirect"},
  { id: 3, name: "CDO Direct"},  
  { id: 4, name: "CDO Indirect"}, 
];
export const StatusOptions = [
  { id: 1, name: "Pending"},
  { id: 2, name: "Approve"},
  { id: 3, name: "Reject"},    
  { id: 4, name: "Terminated"},   
];
export const HeadCountFilterTabConstants = [
  {'id': 0 , 'label': 'Cost Center'}, 
  {'id': 1 , 'label': 'Web Country'}, 
  {'id': 2 , 'label': 'Forecast Group'},
  {'id': 3 , 'label': 'Contact Type'},  
  {'id': 4 , 'label': 'Work Group'},
  {'id': 5 , 'label': 'Site'},  
  {'id': 6 , 'label': 'Cost Center Type'}  
];

export const HeadCountAlertFilterTabConstants = [
  {'id': 0 , 'label': 'Site'}, 
  {'id': 1 , 'label': 'Employee Name'}, 
  {'id': 2 , 'label': 'Attribute Type'},
  {'id': 3 , 'label': 'Status'}
];

export const FilterTypeOperators = [
  {
    id:'string', 
    label: 'String', 
    operators: [{'id': 'startwith', 'label' : 'Starts with'},
      {'id': 'endwith', 'label' : 'Ends with'},
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'contains', 'label' : 'Contains'},
      {'id': 'notContains', 'label' : 'Not contains'}
    ]
  },
  {
    id:'number', 
    label: 'Number', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'lessthan', 'label' : 'Less than'},
      {'id': 'lessthanorequalto', 'label' : 'Less than or equal to'},
      {'id': 'greaterthan', 'label' : 'Greater than'},
      {'id': 'greaterthanorequalto', 'label' : 'Greater than or equal to'}
    ]
  },
  {
    id:'time', 
    label: 'Time', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'},
      {'id': 'lessthan', 'label' : 'Less than'},
      {'id': 'lessthanorequalto', 'label' : 'Less than or equal to'},
      {'id': 'greaterthan', 'label' : 'Greater than'},
      {'id': 'greaterthanorequalto', 'label' : 'Greater than or equal to'}
    ]
  },
  {
    id:'dropdown', 
    label: 'Drop Down', 
    operators: [
      {'id': 'equals', 'label' : 'Equals'},
      {'id': 'notequals', 'label' : 'Not equals'}
    ]
  },
];
export const ColumnItems = [
  { id:"CostCenterID", name: "CostCenterID", type:'string' },
  { id:"WebCountry", name: "WebCountry", type:'string' },
  { id:"ForecastGroup", name: "ForecastGroup", type:'string' },  
  { id:"ContactType", name: "ContactType", type:'string' },
  { id:"WorkGroup", name: "WorkGroup", type:'string' },
  { id:"FTE", name: "FTE", type:'number' },
  { id:"HireDate", name: "HireDate", type:'time' },
  
];
export const logicalOps = [
  { value: 'AND', label: 'And' },
  { value: 'OR', label: 'Or' },
];

export class AlertConstants {
  static readonly Tabs = [
    {
      headerKey: "NEW",      
      value: HCPAlertTabValue.New
    },
    {
      headerKey: "UPDATE",      
      value: HCPAlertTabValue.Update
    },    
  ]
}

export enum AbsenseTypeValues {    
  Internship = "internship",
  Loan = "loan" ,   
}

export const dateFields = ["sD_HireDate", "eD_HireDate", "sD_CostCenterID", "eD_CostCenterID", "sD_FTE", "eD_FTE", "sD_Absense", "eD_Absense", "sD_WorkGroup", "eD_WorkGroup", "sD_EmailID", "eD_EmailID"];
export const oldExportToExcelHCPFieldsArr = ["firstName","lastName","teamMemberID","country","hireDate","sD_HireDate","eD_HireDate","comment_HireDate","costCenterID","sD_CostCenterID","eD_CostCenterID","comment_CostCenterID","costCenterName","costCenterType","fte","sD_FTE", "eD_FTE","comment_FTE","absense","fteValue","sD_Absense","eD_Absense","comment_Absense","workGroup","sD_WorkGroup","eD_WorkGroup","comment_WorkGroup","emailID","sD_EmailID","eD_EmailID","comment_EmailID", "updatedUser"];
export const newExportToExcelHCPFieldsArr = ["First Name","Last Name","Team Member ID","Site","Hire Date","HireDate StartDate","HireDate EndDate","HireDate Comment","Cost Center No","CostCenter StartDate","CostCenter EndDate","CostCenter Comment","Cost Center Name","Cost Center Type","FTE","FTE StartDate", "FTE EndDate","FTE Comment","Absence","FTEValue","Absence StartDate","Absence EndDate","Absense Comment","Work Group","WorkGroup StartDate","WorkGroup EndDate","WorkGroup Comment","Email ID", "EmailID StartDate","EmailID EndDate","Email Comment","Updated By"];

export const oldExportToExcelWorkGroupMappingFieldsArr = ["costCenterNo","workGroupId","webCountryId","forecastGroupId","contactTypeId","staffingPriority"];
export const newExportToExcelWorkGroupMappingFieldsArr = ["Cost Center No","WorkGroup","Web Country","Forecast Group","Contact Type","Staffing Priority"];
export const oldExportToExcelCostCenterMappingFieldsArr = ["costCenterNo","site","level","organization","planningEntity","costCenterType"];
export const newExportToExcelCostCenterMappingFieldsArr = ["Cost Center No","Site","Level","Organization","Planning Entity","Cost Center Type"];
export const oldExportToExcelMasterPageFieldsArr = ["name","description"];
export const newExportToExcelMasterPageFieldsArr = ["Name","Description"];
export const oldExportToExcelCostCenterFieldsArr = ["costCenterNo","description"];
export const newExportToExcelCostCenterFieldsArr = ["Cost Center No","Description"];
export const oldExportToExcelHistoricalFieldsArr = ["versionWeek","year","firstName","lastName","teamMemberID","country","costCenterID","sD_CostCenterID","eD_CostCenterID","costCenterName","costCenterType","fte","sD_FTE", "eD_FTE","absense","fteValue","sD_Absense","eD_Absense","workGroup","sD_WorkGroup","eD_WorkGroup","hireDate","sD_HireDate","eD_HireDate","emailID","sD_EmailID","eD_EmailID"];
export const newExportToExcelHistoricalFieldsArr = ["Week","Year","First Name","Last Name","Team Member ID","Site","Cost Center No","CostCenter StartDate","CostCenter EndDate","Cost Center Name","Cost Center Type","FTE","FTE StartDate", "FTE EndDate","Absence","FTEValue","Absence StartDate","Absence EndDate","Work Group","WorkGroup StartDate","WorkGroup EndDate","Hire Date","HireDate StartDate","HireDate EndDate","Email ID", "EmailID StartDate","EmailID EndDate"];