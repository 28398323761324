
import {forEach, groupBy, sumBy} from "lodash";
import { RTDConstants, StatusColorConstants, compareValues } from "../constants/RTDConstants";
import { CheckAgentValidationAdvanceFilter, checkUndefinedAndNull, checkUndefinedAndNullLength,filterByAgent } from "./helper"
import { sessionQuery, sessionStore } from "../../../state";

export const unsubscribeAgentStatusEvent = (socket) => {
  if (socket) {
    socket.off("agent-status-data")
    socket.off("status")
    
  }
}

export const unsubscribeAgentProfileData = (socket) => {
  if (socket) {
    socket.off("getAgentProfileData")
    socket.off("agentProfiles")
    sessionStore.setAgentProfileData([])
  }
}

export const unsubscribeAgentSummaryEvent = (socket) => {
  if (socket) {
    socket.off("agent-summary-data");
    socket.off("summaryStatus");
  }
}

export const updateAgentStatus = (socket, cb) => {
  if (!socket) return true;
  //socket.off("status");
  socket.on("status", (data) => {
    return cb(null, data);
  });
}

export const updateAgentSummaryStatus = (socket, cb) => {
  if (!socket) return true;
  //socket.off("summaryStatus");
  socket.on("summaryStatus", (data) => {
    return cb(null, data);
  });
}

export const emitGetAgentStatusData = (socket, filterInfo) => {
  socket.emit("getRealTimeData", filterInfo);
}

export const emitGetAgentSummaryData = (socket, filterInfo) => {
  socket.emit("getAgentSummaryRTData", filterInfo);
}

export const emitGetAgentProfileData = (socket): any => {
  socket.emit('getAgentProfileData')
  socket.on("agentProfiles", (data) => {
    const jsonString = JSON.stringify(data);
    const sizeInBytes = new TextEncoder().encode(jsonString).length
    console.log(`Size of array in bytes: ${sizeInBytes}`)
    //agentFiltersCache.set("agentsData", data);
    sessionStore.setAgentProfileData(data)
  });
}

export const proccessUpdateAgentStatusData = (users, agentsChanges, filterValues: any = []) => {
  let changeStatus = agentsChanges[0]
  let changeObj = agentsChanges[1]
  if (!checkUndefinedAndNull(users))
    return []

  if (agentsChanges.length > 1 && !Array.isArray(changeObj)) {
    changeObj = [changeObj];
  }

  changeObj.forEach((item: any) => {
    //let c = item.changeVal
    // let isItemExists = checkAgentValidation(filterValues,item)
    // if(isItemExists){    
    switch (changeStatus) {
      case "insert":
        {
          console.log("insert - " + item.RoutingProfile + " : " + item.FirstName)
          users.push(item)
          break
        }
        
      case "remove":
        {
          console.log("remove - " + item.RoutingProfile + " : " + item.FirstName)
          let index = users.findIndex(obj => obj.id === item.id)
          users.splice(index, 1)
          break
        }
      case "update": {
        console.log("update - " + item.RoutingProfile + " : " + item.FirstName + " : " + item.AgentStatus + ":" + item.ContactState);
        let index = users.findIndex(obj => obj.id === item.id)
        if (index !== -1) {
          users = users.map((a: any) => {
            if (a.id === item.id)
              return item;
            else
              return a;
          });
        } else {
          users.push(item);
        }
        break
      }
    }
    //} 
  })

  return users;
};

export const proccessUpdateAgentSummaryData = (agentSummaries, agentsChanges, filterValues: any = []) => {
    let agents = checkUndefinedAndNullLength(agentSummaries) ? [...agentSummaries] : [];
    let changeStatus = agentsChanges[0]
    let changeUserObj = agentsChanges[1]
    let changeObj = agentsChanges[2]

    if (agentsChanges.length > 1 && !Array.isArray(changeObj)) {
      changeObj = [changeObj];
    }

    changeObj = changeObj.map((a) => {
      return {
        ...a,
        "AgentStatus": a.AgentStatus?.replace("Working On Customer Case", "WOCC"),
        "ContactState": getContactState(a.ContactState, a.AgentStatus)
      }
    })

    let isItemExists = checkAgentValidation(filterValues, changeUserObj)
    if (isItemExists) {
      changeObj = applyAgentFilter(filterValues, changeObj)

      agents = agents.filter(obj => obj.RoutingProfile !== changeUserObj.RoutingProfile)

      console.log(changeStatus + " - " + changeUserObj.RoutingProfile);
      if (changeObj.length > 0) {
        agents = [...agents, ...changeObj]
      }
    }
    return agents
};

export const applyAgentFilter = (filterValues, filtredData) => {
  if (checkUndefinedAndNullLength(filterValues)) {
    let filterObj = filterValues[0]
    if (checkUndefinedAndNull(filterObj))
      filtredData = filterByAgent(filtredData, filterObj)
  }
  return filtredData
}

export const checkAgentValidation = (filterValues, item) => {
  let isValid = true
  let agentFiltersCache = sessionQuery.getAgentProfileData()
  if (!checkUndefinedAndNullLength(filterValues))
    return isValid

  let filterObj = filterValues[0]

  if (isValid && checkUndefinedAndNullLength(filterObj.advanceFilters)) {
    let conditions = filterObj.advanceFilters;
    let filteredData = CheckAgentValidationAdvanceFilter(item, conditions);
    isValid = filteredData;   
  }
  else {
    if (checkUndefinedAndNullLength(filterObj.routingProfiles))
      isValid = filterObj.routingProfiles.findIndex(a => a.value === item.RoutingProfile) !== -1

    if (isValid && checkUndefinedAndNullLength(filterObj.agentStatuses))
      isValid = filterObj.agentStatuses.findIndex(a => a.value === item.AgentStatus) !== -1

    if (isValid && checkUndefinedAndNullLength(filterObj.contactStatuses))
      isValid = filterObj.contactStatuses.findIndex(a => a.value === item.ContactState) !== -1

    if (isValid && checkUndefinedAndNullLength(filterObj.agentNames))
      isValid = filterObj.agentNames.findIndex(a => a.value === `${item.FirstName} ${item.LastName}`) !== -1

    if (isValid && checkUndefinedAndNullLength(filterObj.teamLeads)) {
      if (checkUndefinedAndNullLength(agentFiltersCache)) {
        let currentAgentInfo = agentFiltersCache.find(a => a.fullName === `${item.FirstName} ${item.LastName}`)
        if (checkUndefinedAndNull(currentAgentInfo))
          isValid = filterObj.teamLeads.findIndex(a => a.value === (currentAgentInfo.teamLeadID || currentAgentInfo.managerUserID)) !== -1
      }
    }
    if (isValid && checkUndefinedAndNullLength(filterObj.organizations)) {
      if (checkUndefinedAndNullLength(agentFiltersCache)) {
        let currentAgentInfo = agentFiltersCache.find(a => a.fullName === `${item.FirstName} ${item.LastName}`)
        if (checkUndefinedAndNull(currentAgentInfo))
          isValid = filterObj.organizations.findIndex(a => a.value === currentAgentInfo.organizationName) !== -1
      }
    }
  }
  return isValid
}

export const getContactState = (contactState, agentStatus) => {
  let contactStateVal = contactState
  let otherStatuses = [RTDConstants.CallBack,RTDConstants.CaseManager,RTDConstants.JRDAPTicket,RTDConstants.PPAGCase,
    RTDConstants.Phone, RTDConstants.Outbound, RTDConstants.Chat,RTDConstants.ChatAndEmail, RTDConstants.ChatWrapup,
    RTDConstants.DIFYRequests,RTDConstants.DesignLive,RTDConstants.DesignerCheckIn, RTDConstants.Email]

  if(otherStatuses.includes(agentStatus)){
    switch (contactState) {
      case "":
        contactStateVal = RTDConstants.Ready;
        break
      default:
        contactStateVal = contactState.replace("CONNECTED_ONHOLD", "ONHOLD")
        break
    }
  }
  return contactStateVal
}

export const getColorValue = (rules, valObj) => {
  var statusClass = "#F8F8F4";

  if (checkUndefinedAndNull(rules) && rules.length > 0) {
    let comparisonResult = false;
    let bgColor = ''
    rules.map(item => {
      let previousConditionColumn: string | null = null;
      let result = false
      const { backcolor, conditions } = item
      let conditionList = JSON.parse(conditions)

      conditionList.sort((a, b) => {
        if (a.conditionColumn < b.conditionColumn) {
          return -1;
        } else if (a.conditionColumn > b.conditionColumn) {
          return 1;
        } else {
          return 0;
        }
      })

      conditionList.forEach((a) => {
        const { conditionColumn, operator, value } = a;
        let targetValue = value
        let sourceValue = valObj[conditionColumn]
        let valueResult = compareValues(sourceValue, operator, targetValue)
        result = previousConditionColumn !== null && previousConditionColumn !== conditionColumn ?
          result && valueResult : result || valueResult

        previousConditionColumn = conditionColumn;
      })
      if (result) {
        bgColor = backcolor
      }
      return comparisonResult = comparisonResult || result
    })

    return comparisonResult ? bgColor : statusClass
  } else {
    let contactState = getContactState(valObj.AgentStatus, valObj.ContactState)
    switch (valObj.AgentStatus) {
      case "Phone":
        switch (contactState) {
          case "": {
            let statusColorObj = StatusColorConstants.find(item => item.Status.toLowerCase() === "empty")
            statusClass = statusColorObj !== undefined ? statusColorObj.Color : "#81c784"
            break;
          }
          default: {
            let statusColorObj = StatusColorConstants.find(item => item.Status.toLowerCase() === contactState.toLowerCase())
            statusClass = statusColorObj !== undefined ? statusColorObj.Color : "#81c784"
          }
        }
        break;
      default:
        {
          let statusColorObj = StatusColorConstants.find(item => item.Status.toLowerCase() === valObj.AgentStatus.toLowerCase())
          statusClass = statusColorObj !== undefined ? statusColorObj.Color : "#81c784"
        }
        break;
    }
    return statusClass
  }
}

export const hashingData = (response) => {
  var HashMap = require('hashmap');
  let customTable = new HashMap();
  let groupKey = 'RoutingProfile'
  if (response && response.length) {
    let responseData = response
    responseData.map((item: any) => {
      if (customTable.get(item[groupKey]) === undefined) {
        customTable.set(item[groupKey], {
          id: item[groupKey] !== null ? `Marquet : ${item[groupKey]}` : `Default`,
          RoutingProfile: item[groupKey] !== null ? `Marquet : ${item[groupKey]}` : `Default`,
          FullName: '',
          AgentStatus: '',
          ContactState: '',
          StatusStartTimestamp: '',
          items: [],
        })
      }
      let chatQueueItem = customTable.get(item[groupKey]);
      //let count = chatQueueItem.items.length + 1;
      chatQueueItem.id = item[groupKey] !== null ? `${item[groupKey]}` : `Default`
      chatQueueItem.RoutingProfile = item[groupKey] !== null ? `${item[groupKey]}` : `Default`
      chatQueueItem['FullName'] = chatQueueItem['FullName'] + item['FullName']
      chatQueueItem['AgentStatus'] = chatQueueItem['AgentStatus'] + item['AgentStatus']
      chatQueueItem['ContactState'] = chatQueueItem['ContactState'] + item['ContactState']
      chatQueueItem['StatusStartTimestamp'] = chatQueueItem['StatusStartTimestamp'] + item['StatusStartTimestamp']
      chatQueueItem.items.push(item);
      return customTable.set(item[groupKey], chatQueueItem);
    })
  }
}

export const groupByItem = (arr: any[], keys: string[]) => {
  let groupData: any = []
  let outputData = groupBy(arr, item => {
    return keys.map(key => item[key]).join('-');
  })

  forEach(outputData, (value, key) => {
    let splitKey = key.split('-')
    let sumAgentCount = sumBy(value, 'AgentCount')
    groupData.push({
      AgentStatus: splitKey[0],
      ContactState: splitKey[1],
      AgentCount: sumAgentCount
    })
  })

  return groupData;
};