import { DataTable } from "primereact/datatable";
import { IColumn } from "../../../../../contracts/IColumn";
import { Column } from "primereact/column";
import { useUserContext } from "../../../../../contextProviderFiles/UserContextProvider";
import PageLoader from "../../../../../components/PageLoader";
import { Box, Button, Tooltip } from "@mui/material";
import { formatDate } from "../../../services/helper";
import { HCPAlertStatus } from "../../../../../enums/common.enum";
import UpdateIcon from '@mui/icons-material/Update';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {ConfirmDialog,confirmDialog } from 'primereact/confirmdialog';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs'
import './HeadCountHistoricalAlertMasterList.css';

const HeadCountHistoricalAlertMasterList = (props) => {
  const { alertData, globalFilters, onQuickUpdate, onCustomUpdateCallback } = props;
  const { loader } = useUserContext();
  const quickUpdate = <span>Quick Update</span>;
  const decline = <span>Decline</span>;
  const customUpdate = <span>Custom Update</span>; 
  const [status, setStatus] = useState(1);
  const [alertsData, setAlertData] = useState(alertData);
  let customUpdateSelectedDate: any;

  useEffect(() => {
    setAlertData(alertData);
  }, [alertData]);
  
  const columns: IColumn[] = [
    {
      id: "id",
      label: "Id",
      visible: false,
    },
    {
      id: "userName",
      label: "Employee Name",
      visible: true,
      sortable: true,
      minWidth: 10,
    },
    {
      id: "teamMemberId",
      label: "Team Member Id",
      visible: true,
      sortable: true,
      minWidth: 10,
    },
    {
      id: "country",
      label: "Site",
      visible: true,
      sortable: true,
      minWidth: 10,
    },
    {
      id: "fieldsDetails",
      label: "Alert",
      visible: true,
      minWidth: 430,
      sortable: false,
      customTemplate: (value: any) => {
        const isTerminated = value.status === HCPAlertStatus.Terminated;
        const className = isTerminated ? "terminated-row" : "";
        return (
          <div className={className}>
            {value?.fieldDetails === null ? "User is terminated" : formatFieldDetails(value?.fieldDetails)}
          </div>
        );
      }
    },
    {
      id: "status",
      label: "Status",
      visible: true,
      minWidth: 20,
      sortable: true,
      customTemplate: (value: any) => {
        return <Box>{getStatusDescription(value.status)}</Box>;
      }
    },
    {
      id: "date",
      label: "Date",
      visible: true,
      minWidth: 20,
      sortable: true,
      align: "right",
      customTemplate: (value: any) => {
        return (
          formatDate(value?.date)
        );
      }
    },
    {
      id: "id",
      label: "Action",
      minWidth: 20,
      align: "right",
      visible: true,
      customTemplate: (value: any) => {
        if (value.status === HCPAlertStatus.Pending) {
          return (
            <div className='cardsactions1'>
              <div className='cardsbutn'>
                <Tooltip placement='top' title={customUpdate}>
                  <Button className="p-button-update" onClick={() => confirmUpdate(value, 'center')} >
                    <UpdateIcon style={{ fontSize: 20 }} />
                  </Button>
                </Tooltip>
                <Tooltip placement='top' title={quickUpdate} className="acceptbutn">
                  <Button onClick={() => confirmPositionViewAll(value, 'top-right')} className="p-button-warning">
                    <CheckRoundedIcon style={{ color: 'green' }} />
                  </Button>
                </Tooltip>
                <Tooltip placement='top' title={decline}>
                  <Button onClick={() => confirmReject(value, 'top-right')} className="p-button-warning" >
                    <ClearRoundedIcon style={{ color: 'red' }} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )
        }
      },
    },
  ];
  const onPageChange = (status, val) => {
    if (onQuickUpdate !== undefined) {
      var request = { status: status, alertData: val };
      onQuickUpdate(request);
    }
  };  
  const acceptFunc = (status, alert) => {
    setStatus(status);
    onPageChange(status, alert);
    updateAlertStatus(alert.id, status);
  };  
  const rejectFunc = (status, alert) => {
    setStatus(status);
     onPageChange(status, alert);
    updateAlertStatus(alert.id, status);
  };
  const updateAlertStatus = (alertId, status) => {
    const updatedAlerts = alertData.map(alert => 
      alert.id === alertId ? { ...alert, status } : alert
    );
    setAlertData(updatedAlerts);
  };
  const acceptAlertFunc = (item, val) => {
    setStatus(item);
    onCustomAlertChange(item, val);
    updateAlertStatus(val.id, item);  
  }
  const onCustomAlertChange = (status, val) => {
    if (onCustomUpdateCallback !== undefined) {
      var request = { status: status, alertData: val, newStartDate: customUpdateSelectedDate };
      onCustomUpdateCallback(request);
    }
  };
  const confirmPositionViewAll = (val, positicon: string) => {  
      confirmDialog({
        message: 'Do you want to Accept this ' + val.userName + ' record?',
        header: 'Accept Alert',
        icon: 'pi pi-info-circle',
        position: "center",
        accept: () => {
          acceptFunc(HCPAlertStatus.Approve, val);       
        }       
      }); 
  };
  const confirmReject = (val, positicon: string) => {  
      confirmDialog({
        message: 'Do you want to Reject this ' + val.userName + ' record?',
        header: 'Reject Alert',
        icon: 'pi pi-info-circle',
        position: "center",
        accept: () => {
          rejectFunc(HCPAlertStatus.Reject, val);       
        }     
      });  
  };
  const confirmUpdate = (val, position: string) => {  
      confirmDialog({
        message:
          <div style={{ width: '170px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Calendar
              showIcon
              id="startDate"
              name="startDate"
              onChange={newSelectedStartDateForConfirmUpdate}
            /></div>,
        header: 'Choose Start Date',
        position: "center",
        acceptLabel: 'Ok',
        rejectLabel: 'Cancel',
        accept: () => {
          acceptAlertFunc(HCPAlertStatus.Approve, val);         
        }       
      });  
  };
  const newSelectedStartDateForConfirmUpdate = (event) => {
    const selectedDate = dayjs(event.value).startOf('day').toDate();
    customUpdateSelectedDate = selectedDate;
  };
  const getStatusDescription = (status: HCPAlertStatus): string => {
    return HCPAlertStatus[status] || "";
  }

  const formatFieldDetails = (fieldDetails) => {

    let data = (typeof fieldDetails === 'string') ? JSON.parse(fieldDetails) : fieldDetails;
    return data.map(({ FieldName, OldValue, NewValue }) => {
      const oldValue = OldValue === '01/01/1900' ? '' : OldValue;
      const newValue = NewValue === '01/01/1900' ? '' : NewValue;

      const oldValueText = oldValue !== null && oldValue !== '' ? ` from ${oldValue}` : '';
      const newValueText = newValue !== null && newValue !== '' ? ` to ${newValue}` : '';

      if (OldValue && NewValue) {
        return `${FieldName} updated${oldValueText}${newValueText}`;
      } else if (OldValue || NewValue) {
        return `${FieldName} updated${oldValueText}${newValueText}`;
      }
      return '';
    }).filter(Boolean).join('. \n');
  };

  return (
    <>
      {!loader && (
        <DataTable
          value={alertsData} removableSort
          scrollable paginator responsiveLayout="scroll"
          filters={globalFilters}
          globalFilterFields={['userName', 'teamMemberId','country', 'fieldsDetails', 'status', 'date']}
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10,50, 100, 200]}
        >
          {
            columns.map((column, index) => {
              return (
                <Column key={index}
                  sortable={column.sortable !== undefined ? column.sortable : false}
                  resizeable={column.resizeable !== undefined ? column.resizeable : false}
                  hidden={!column.visible}
                  field={column.id}
                  body={column.customTemplate}
                  header={column.label}
                  style={{ minWidth: column.minWidth ? column.minWidth + 'px' : undefined }}
                >
                </Column>
              );
            })
          }
        </DataTable>
      )}
      {loader && <PageLoader />}     
      <ConfirmDialog />
    </>
  );
};
export default HeadCountHistoricalAlertMasterList;