import { countBy, forEach, groupBy, isNumber } from "lodash";
import { checkUndefinedAndNull, checkUndefinedAndNullLength, filterByCareDesign } from "./helper";
import { ViewTypeConstants } from "../constants/RTDConstants";

export const emitCareDesignData = (socket, filterInfo): any => {
    socket.emit("getDesignAnalysisData");
}
export const updateCareDesignData = (socket, filterObj, widgetInfo, cb, queryClient) => {
    if (!socket) return true;
    socket.on("designRequestData", (currentData: any) => {
        if(checkUndefinedAndNullLength(currentData)){
            queryClient.setQueryData(["openTicketStatus"],() => {
                return currentData
            })
            let combinedData = processOpenTicketStatus(currentData,filterObj,widgetInfo)
            cb(null, combinedData)
        } else{
            cb(null,currentData)
        }
    })
}

export const unsubscribeCareDesignEvent = (socket) => {
    if (socket) {
        socket.off("designRequestData")
    }
}

export const processOpenTicketStatus = (currentData, filterObj, widgetInfo ) => {
    let filterValues = checkUndefinedAndNull(filterObj) ? filterObj.filterValues : []
    let responseData = currentData;
    if (filterValues.length > 0) {
        let filterObj = filterValues[0];
        if (checkUndefinedAndNull(filterObj)) {
            responseData = filterByCareDesign(responseData, filterObj);
        }
    }
    let combinedData
    if(checkUndefinedAndNullLength(responseData)){
        let groupField = widgetInfo.groupField
        let subLevelGroupFields = widgetInfo.subLevelGroupFields

        groupField = checkUndefinedAndNull(groupField) ? groupField : "Type"
        subLevelGroupFields = checkUndefinedAndNull(subLevelGroupFields) ? subLevelGroupFields : ["Locale", "Status"]
        let subLevelGroupItems = typeof(subLevelGroupFields) === 'string' ? JSON.parse(subLevelGroupFields) : subLevelGroupFields
        const updatedData = getOpenTicketStatus(widgetInfo?.viewTypeId, responseData, groupField,subLevelGroupItems[0], subLevelGroupItems[1])
        combinedData = { 'processData': updatedData, 'rowData': responseData}
    } else {
        combinedData = { 'processData': [], 'rowData': responseData}
    }
    return combinedData
}

const getOpenTicketStatus = (widgetType, response, groupField, secondLevelGroup, thirdLevelGroupField:any=undefined) => {
    if (response && response.length) {
        let recordsByType = groupDesignData(response, [groupField], widgetType);

        let combinedTicketRequests = recordsByType.map((item, key) =>{
            let groupFieldValue = item.Name;
            let Level1FilteredData = response.filter(a=>a[groupField] === groupFieldValue);            
            let localeRecords = groupDesignData(Level1FilteredData, [secondLevelGroup], widgetType);

            localeRecords = localeRecords.map((item, key) =>{
                let Level2FilteredData = Level1FilteredData.filter(a => a[secondLevelGroup] === item.Name)
                if(checkUndefinedAndNull(thirdLevelGroupField)){
                    let statusGroupedData = groupDesignData(Level2FilteredData, [thirdLevelGroupField], widgetType);

                    statusGroupedData = statusGroupedData.map((a) =>{ 
                        return {
                            key: `${groupFieldValue}:${item.Name}:${a.id}:${key}`, 
                            data:{...a,'id': `${groupFieldValue}:${item.Name}:${a.id}:${key}` ,'idFields': `${groupField}:${secondLevelGroup}:${thirdLevelGroupField}`}
                        } 
                    })

                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}, 
                        children: statusGroupedData
                    }
                } else {
                    return {
                        key: `${groupFieldValue}:${item.id}-${key}`, 
                        data: {...item,'id' : `${groupFieldValue}:${item.id}:${key}`, 'idFields': `${groupField}:${secondLevelGroup}`}, 
                    }
                }
            })

            return {
                key: `${item.id}:${key}`, 
                data : {...item, 'idFields': `${groupField}`}, 
                children: localeRecords
            }
        })

        return combinedTicketRequests
    }
}

const groupDesignData = (records, keys, widgetType) => {
    let groupData:any = []
    let outputData = groupBy(records, item => {
        return keys.map(key => item[key]).join(':');
    })
    
    forEach(outputData, (value, key) => {
        let splitKey = key.split(':');
        let updatedHoursDeadlineCount : any;

        if(widgetType === ViewTypeConstants.IntervalView.id) 
        {
            let nextHoursColumns = generateDynamicColumns();
            const filteredDates = filterDueDates(value);
            updatedHoursDeadlineCount = {...nextHoursColumns, ...filteredDates };
        }
        else{
            
            let sumRequestCount = countBy(value, 'HoursBeforeDeadline')
            let hoursDeadlineCount = { 'Missed': 0, '<3': 0,'<6': 0,'<9': 0,'<12': 0,'<15': 0,'<18': 0,'>18': 0};
            updatedHoursDeadlineCount = {...hoursDeadlineCount, ...sumRequestCount }
        }
        const total = Object.values(updatedHoursDeadlineCount)
            .reduce((acc: any, value) => acc + (typeof value === 'number' ? value : 0), 0)
    
        groupData.push({
            'id': key,
            'Name':splitKey[keys.length - 1],
            ...updatedHoursDeadlineCount,
            'Total': total
        })
    })

    return groupData
}

//------------------Process Interval Data ----------------------------------------
export const generateDynamicColumns = () => {
    const currentDate = new Date();
    const columnCount = 8;
    const hourMilliseconds = 60 * 60 * 1000;
    let allColumns = {};

    for (let i = 1; i <= columnCount; i++) {
        const newDate = new Date(currentDate.getTime() + (i * hourMilliseconds));
        const columnName = `${newDate.getHours().toString().padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}`;
        allColumns[columnName] = 0;
    }

    return allColumns;
};

// Function to filter due dates within the next hour
const filterDueDates = (value) => {
    const columnData = generateDynamicColumns();
    let sourceDate = new Date();
    let allColumns = {};
    let filteredDates = [];
    for (const columnName in columnData) {
        if (columnData.hasOwnProperty(columnName)) {
            let count = 0;
            const [hourStr, minuteStr] = columnName.split(':');
            const hour = parseInt(hourStr, 10);
            const minute = parseInt(minuteStr, 10);
            
            let nextHourDate = new Date();
            nextHourDate.setHours(hour);
            nextHourDate.setMinutes(minute);
            
            filteredDates = value.filter(row => {
                const dateToCheck = new Date(row?.DueDate);
                if(dateToCheck >= sourceDate && dateToCheck <= nextHourDate){
                    count += 1;
                    row.HoursBeforeDeadline = columnName;
                }
            });

            allColumns[columnName] = count;
            sourceDate = nextHourDate;
        }
    }
    return allColumns;    
};