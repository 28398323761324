import { forwardRef, useEffect, useState } from "react";
import { useRTDService } from "../../../../services/RTDService";
import "../../../pages/AgentStatusPage/AgentStatusPage.css";
import {
  applyAgentFilter,
  checkAgentValidation,
  getContactState,
  proccessUpdateAgentStatusData,
  updateAgentStatus,
} from "../../../../services/agentStatusService";
import { WidgetComponentConstants } from "../../../../constants/RTDConstants";
import { IColumn } from "../../../../../../contracts/IColumn";
import TimeStatusComponent from "../TimeStatusComponent/TimeStatusComponent";
import DynamicComponent from "../../../../../Core/components/DynamicComponent";
import { checkUndefinedAndNull, checkUndefinedAndNullLength } from "../../../../services/helper";
import { useRTDWidgetDataContext } from "../../../../contextProviders/RTDWidgetDataContextProvider";
import PageLoader from "../../../../../../components/PageLoader";
const AgentStatus = forwardRef((props: any, ref) => {
  const { widgetInfo, filterInfo, onChangeItem, globalFilterInfo, clientSocket } = props
  const [agentListData, setAgentListData] = useState<any>(undefined)
  const rtdService = useRTDService()
  const { agentStatusData } = useRTDWidgetDataContext()
  const columns: IColumn[] = [
    {
      id: "FullName",
      label: "Full Name",
      minWidth: 250,
      visible: true,
      sortable: true,
      resizeable: true
    },
    {
      id: "Username",
      label: "Username",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true
    },
    // {
    //   id: "ActivityName",
    //   label: "Schedule",
    //   minWidth: 250,
    //   visible: true,
    //   sortable: true,
    //   resizeable: true,
    //   customTemplate: (value) => {
    //     let text = `Start Time: ${dayjs(value?.ActivityStart).format(dateTimeFormat)} End Time: ${dayjs(value?.ActivityEnd).format(dateTimeFormat)}`
    //     return (<div>
    //       <Tooltip title={text}>
    //         <span className="tooltip-text">{value.ActivityName}</span>
    //       </Tooltip>
    //     </div>)
    //   }
    // },
    {
      id: "RoutingProfile",
      label: "Routing Profile",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true
    },
    {
      id: "AgentStatus",
      label: "Agent Status",
      minWidth: 250,
      sortable: true,
      resizeable: true,
      visible: true,
      defaultFormat: (obj) => {
        return (
          <div className={rtdService.getColor(obj.AgentStatus, obj.ContactState)}>
            {obj.AgentStatus}
          </div>
        )
      },
    },
    {
      id: "ContactState",
      label: "Contact State",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true
    },
    {
      id: "PreviousAgentStatus",
      label: "Previous Status",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true,
      defaultFormat: (obj) => {
        return (
          <div className={rtdService.getColor(obj.PreviousAgentStatus, obj.ContactState)}>
            {obj.PreviousAgentStatus}
          </div>
        )
      }
    },
    {
      id: "Capacity",
      label: "Work Load",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true,
      customTemplate: (value) => {
        let workload = checkUndefinedAndNull(value.WorkLoad) ? value.WorkLoad : 0
        let capacity = checkUndefinedAndNull(value.Capacity) ? value.Capacity : 0
        return `${workload}/${capacity}`
      }
    },
    {
      id: "PreviousAgentStatus",
      label: "Previous Status",
      minWidth: 250,
      sortable: true,
      resizeable: true,
      visible: true
    },
    {
      id: "CapacityPercentage",
      label: "Capacity",
      minWidth: 250,
      resizeable: true,
      sortable: true,
      visible: true,
      customTemplate: (value) => `${checkUndefinedAndNull(value.CapacityPercentage) ? value.CapacityPercentage : 0}%`
    },
    {
      id: "StatusStartTimestamp",
      label: "Status Time",
      minWidth: 250,
      sortable: true,
      resizeable: true,
      visible: true,
      customTemplate: (value) => {
        return (
          value && <TimeStatusComponent agent={value} thresholdTime={null} rules={widgetInfo.appliedCFRules} />
        );
      },
      defaultFormat: (value) => {
        return (
          value && <TimeStatusComponent agent={value} thresholdTime={null} />
        );
      }
    }
  ];
  // useEffect(() => {
  //   let socket = rtdService.initialSocketConnection(clientSocket);
  //   setClientSocket(socket);
  //   return () => {
  //     setAgentListData([])
  //     unsubscribeAgentStatusEvent(clientSocket)
  //     if (clientSocket) {
  //       console.log("Disconnecting socket...")
  //       clientSocket.disconnect()
  //     }
  //   };
  // }, [clientSocket]);
  useEffect(() => {
      let currentSData = applyAgentFilter(filterInfo?.filterValues,agentStatusData)
      if(checkUndefinedAndNullLength(currentSData)){
        currentSData = currentSData.map((a) => {
          return {
            ...a,
            FullName: `${a.FirstName} ${a.LastName}`,
            AgentStatus: a.AgentStatus.replace(
              "Working On Customer Case",
              "WOCC"
            ),
            PreviousAgentStatus: a.PreviousAgentStatus.replace(
              "Working On Customer Case",
              "WOCC"
            ),
            ContactState: getContactState(a.ContactState, a.AgentStatus),
          }
        })
        setAgentListData(currentSData ?? [])
      }
      //let agentStatusData = [...currentSData]
      setAgentListData(currentSData ?? [])
      //let agentStatusData = [...currentSData]
      updateAgentStatus(clientSocket, (err, currentData) => {
        console.log(widgetInfo.name)
        let crntItem = currentData[1];
        let validItem = checkAgentValidation(filterInfo?.filterValues, crntItem)
        if (validItem) {
          let updatedData = proccessUpdateAgentStatusData(currentSData, currentData, filterInfo?.filterValues)
          //updatedData = applyAgentFilter(filterInfo?.filterValues, updatedData);
          updatedData = updatedData.map((a) => {
            return {
              ...a,
              FullName: `${a.FirstName} ${a.LastName}`,
              AgentStatus: a.AgentStatus.replace(
                "Working On Customer Case",
                "WOCC"
              ),
              PreviousAgentStatus: a.PreviousAgentStatus.replace(
                "Working On Customer Case",
                "WOCC"
              ),
              ContactState: getContactState(a.ContactState, a.AgentStatus),
            };
          });
          setAgentListData(updatedData ?? []);
          currentSData = [...updatedData];
        } else {
          if(checkUndefinedAndNullLength(currentSData)){
            let index = currentSData.findIndex(
              (obj) => obj.id === crntItem.id
            );
            if (index !== -1) {
              currentSData.splice(index, 1);
            }
            setAgentListData(currentSData ?? []);
          } else {
            setAgentListData([]);
          }
        }
      })
  }, [agentStatusData, filterInfo]);
  const calculateAgentCount = (name) => {
    let total = 0
    if (agentListData) {
      for (let agent of agentListData) {
        if (agent.RoutingProfile === name) {
          total++;
        }
      }
    }
    return `${name}:${total} agent(s)`
  };
  const rowHeaderGroupTemplate = (data) => {
    return (
      <>
        {calculateAgentCount(data.RoutingProfile)}
      </>
    )
  }
  const getWidgetItemByType = () => {
    let currentWidgetComponent: any = WidgetComponentConstants.find(
      (item) => item.widgetId === widgetInfo.widgetType
    );
    let currentWidgetItem = currentWidgetComponent?.items?.find(
      (item) => item.id === widgetInfo.viewItemId
    );
    let currentWidgetType = currentWidgetItem?.viewTypes?.find(
      (item) => item.id === widgetInfo.viewTypeId
    );
    return (
      <DynamicComponent
        component={currentWidgetType?.viewCompoent}
        data={agentListData}
        widgetInfo={widgetInfo}
        columns={columns}
        onChangeItem={onChangeItem}
        rowHeaderGroupTemplate={rowHeaderGroupTemplate}
        globalFilterInfo={globalFilterInfo}
      />
    )
  }
  return (
    <>
      {agentListData && (<div
        className="role-content"
        style={{ overflow: "auto", height: "auto", position: "relative" }}
      >
        {getWidgetItemByType()}
      </div>)}
      {!agentListData && <PageLoader />}
    </>
  );
});
export default AgentStatus;